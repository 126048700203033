import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../../security/service/auth.service';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { LocalizationService } from '../../../localization/service/localization.service';
import emailMask from 'text-mask-addons/dist/emailMask';
import { SubSink } from '../../../localization/utils';
import { ConfigService } from '@ngx-config/core';
import { fromEvent, interval, Subscription } from 'rxjs';
import { Constants } from '../../../constants/Constants';

function confirmPasswordValidator(input: FormControl) {
    if (!input.value) {
        return null;
    }
    const exactMatch = input.parent.get('password').value === input.value;
    return exactMatch ? null : { mismatchedPassword: true };
}

function passwordSpecRulesValidator(input: FormControl) {
    if (!input.value) {
        return null;
    }
    const exactMatch = input.value.match(/^(?=.*[0-9])(?=.*[a-zа-яё])(?=.*[A-ZА-ЯЁ])([a-zA-Zа-яёА-ЯЁ0-9]{8,})$/);
    return exactMatch ? null : { notValid: true };
}

declare var $: any;

@Component({
    selector: 'app-registration',
    templateUrl: './registration.component.html',
    styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent implements OnInit, OnDestroy {
    public formProcessing = false;
    public isEnabledUserAgreement: boolean;

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly authService: AuthService,
        private readonly router: Router,
        private readonly messageService: MessageService,
        private readonly localizationService: LocalizationService,
        private readonly configService: ConfigService,
    ) {}
    @ViewChild('regByEmailTab') regByEmailTab: ElementRef;
    @ViewChild('codeFrom') codeFrom: ElementRef;
    public regByPhone = true;
    public emailMask = emailMask;
    public regForm: FormGroup;
    @Input()
    public step;
    @Output()
    public changeStep = new EventEmitter<number>();
    public nonValidPhoneMessage: string;
    public otpSendingLockedMessage: string;
    public nonValidOtpCodeMessage: string;
    public nonValidOtpCodeEmail: string;
    public moreOtpRequest: string;
    public otpExpiredMessage: string;
    public otpAttemptsOverMessage: string;
    public anonymousSessionExpired: string;
    public otpLockedMessage: string;
    public badRequest: string;
    public emailAlreadyExistMessage: string;
    public msisdnAlreadyExistMessage: string;
    public readonly subSink: SubSink = new SubSink();
    public currentMsisdn = '+79000000000';
    public currentEmail: string;
    public anonymousSessionId: string;
    public step2Content1: string;
    public step2Content2: string;
    public step2EmailContent1: string;
    public step2EmailContent2: string;
    public passwordShown = false;
    public confirmPasswordShown = false;
    public isEnabledEmail = true;
    // tslint:disable-next-line:no-output-on-prefix
    @Output() onStep3 = new EventEmitter<boolean>();
    @Input()
    public capchaChecked: boolean;
    public isEnabledRecaptcha: boolean;
    public reCaptchaSitekey: string;
    public capchaLang: string;
    public otpLinkTitle: string;
    private sub: Subscription;
    public personalDataLink: string;

    public static getStringTimerValue(otpLockPeriod: number): string {
        const countDownTime = new Date(otpLockPeriod);
        // @ts-ignore
        const days = Math.floor(countDownTime / (1000 * 60 * 60 * 24));
        // @ts-ignore
        const hours = Math.floor((countDownTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        // @ts-ignore
        const minutes = Math.floor((countDownTime % (1000 * 60 * 60)) / (1000 * 60));
        // @ts-ignore
        const seconds = Math.floor((countDownTime % (1000 * 60)) / 1000);

        return (
            (days ? days.toString() + ':' : '') +
            (hours ? (hours / 100).toFixed(2).substring(2) + ':' : '') +
            (minutes / 100).toFixed(2).substring(2) +
            ':' +
            (seconds / 100).toFixed(2).substring(2)
        );
    }

    ngOnInit(): void {
        const { auth } = this.configService.getSettings('enableEmail');
        this.isEnabledRecaptcha = this.configService.getSettings('enableRecaptcha');
        this.isEnabledEmail = auth;

        this.isEnabledUserAgreement = this.configService.getSettings('enableUserAgreement');
        this.personalDataLink = this.configService.getSettings('registrationPersonalDataLink');
        this.regForm = this.formBuilder.group({
            phoneLogin: ['', Validators.required],
            agreementReg: [false, Validators.requiredTrue],
            emailLogin: [
                '',
                [Validators.required, Validators.pattern(Constants.email)],
            ],
            otpCode: ['', [Validators.required, Validators.pattern(/^\d{6}$/)]],
            password: ['', [Validators.required, passwordSpecRulesValidator]],
            confirmPassword: ['', [Validators.required, confirmPasswordValidator]],
            userAgreement: [''],
        });
        this.subSink.sink = this.localizationService.languageChanged.subscribe(() => {
            this.nonValidPhoneMessage = this.localizationService.getMessage('reg.nonValidPhoneMessage');
            this.otpSendingLockedMessage = this.localizationService.getMessage('reg.otpSendingLockedMessage');
            this.step2Content1 = this.localizationService.getMessage('reg.step2Content1');
            this.step2Content2 = this.localizationService.getMessage('reg.step2Content2');
            this.step2EmailContent1 = this.localizationService.getMessage('reg.step2EmailContent1');
            this.step2EmailContent2 = this.localizationService.getMessage('reg.step2EmailContent2');
            this.nonValidOtpCodeMessage = this.localizationService.getMessage('reg.nonValidOtpCodeMessage');
            this.nonValidOtpCodeEmail = this.localizationService.getMessage('reg.nonValidOtpCodeEmail');
            this.moreOtpRequest = this.localizationService.getMessage('reg.moreOtpRequest');
            this.otpExpiredMessage = this.localizationService.getMessage('reg.otpExpired');
            this.anonymousSessionExpired = this.localizationService.getMessage('reg.anonymousSessionExpired');
            this.otpAttemptsOverMessage = this.localizationService.getMessage('reg.otpAttemptsOverMessage');
            this.otpLockedMessage = this.localizationService.getMessage('reg.otpLockedMessage');
            this.capchaLang = this.localizationService.getMessage('capchaLang');
            this.otpLinkTitle = this.localizationService.getMessage('reg.otpLinkTitle');
            this.emailAlreadyExistMessage = this.localizationService.getMessage('reg.emailAlreadyExistMessage');
            this.msisdnAlreadyExistMessage = this.localizationService.getMessage('reg.msisdnAlreadyExistMessage');
            this.badRequest = this.localizationService.getMessage('badRequest');
        });
        this.changeStep.emit(1);
        this.capchaChecked = false;
        this.reCaptchaSitekey = this.configService.getSettings('re_captcha_sitekey');
        fromEvent(document, 'show.bs.modal').subscribe(() => {
            this.regForm.reset();
            if (this.sub) {
                this.messageService.clear();
                this.sub.unsubscribe();
            }
            this.messageService.clear();
        });
    }

    onKeyUp(): void {
        if (this.step === 2 && this.isValidByStep2()) {
            this.onRegFormStep2Submit();
        }
    }

    isValidByStep1() {
        let result;

        if (this.isEnabledRecaptcha) {
            result = this.regByPhone
              ? this.regForm.controls['phoneLogin'].valid && this.capchaChecked
              : this.regForm.controls['emailLogin'].valid && this.capchaChecked;
        } else {
            result = this.regByPhone
              ? this.regForm.controls['phoneLogin'].valid
              : this.regForm.controls['emailLogin'].valid;
        }

        result = result && this.regForm.controls['agreementReg'].valid;

        return result;
    }

    isValidByStep2() {
        return this.regForm.controls['otpCode'].valid;
    }

    isValidByStep3() {
        const userAgreement = this.isEnabledUserAgreement ? this.regForm.controls['userAgreement'].value : true;
        return (
            this.regForm.controls['password'].valid &&
            this.regForm.controls['confirmPassword'].valid &&
            this.regForm.controls['password'].value === this.regForm.controls['confirmPassword'].value &&
            userAgreement
        );
    }

    public onRegFormStep1Submit(isNeedMoreOtp = false): void {
        this.formProcessing = true;
        let errorMessage;
        const value = this.regForm.value;
        const registerObserver = {
            next: (response) => {
                if (isNeedMoreOtp) {
                    this.messageService.clear();
                    this.messageService.add({
                        severity: 'success',
                        summary: '',
                        detail: this.moreOtpRequest,
                        sticky: true,
                    });
                    if (this.codeFrom && this.codeFrom.nativeElement) {
                        this.codeFrom.nativeElement.click();
                    }
                }
                this.changeStep.emit(2);
                this.capchaChecked = false;
                this.regForm.controls['otpCode'].setValue('');
                this.anonymousSessionId = response.sessionId;
                this.formProcessing = false;
            },
            error: (errorData) => {
                this.messageService.clear();
                if (errorData.error.status === 'INVALID_AUTHORIZATION_DATA') {
                    errorMessage = this.nonValidPhoneMessage;
                } else if (errorData.error.status === 'OTP_LOCKED') {
                    errorMessage = this.otpLockedMessage.replace(
                        'otpLockPeriod',
                        RegistrationComponent.getStringTimerValue(errorData.error.otpLockPeriod),
                    );
                    this.startOtpLockTimer(errorData.error.otpLockPeriod);
                } else if (errorData.error.status === 'EMAIL_ALREADY_EXISTS') {
                    errorMessage = this.emailAlreadyExistMessage;
                } else if (errorData.error.status === 'MSISDN_ALREADY_EXISTS') {
                    errorMessage = this.msisdnAlreadyExistMessage;
                } else if (errorData.status === 504) {
                    errorMessage = this.badRequest;
                } else {
                    errorMessage =
                        typeof errorData.error === 'string' ? errorData.message : errorData.error.description;
                }
                this.messageService.add({
                    severity: 'error',
                    summary: '',
                    detail: errorMessage,
                    sticky: true,
                });
                if (this.codeFrom && this.codeFrom.nativeElement) {
                    this.codeFrom.nativeElement.click();
                }
                this.formProcessing = false;
            },
        };
        if (this.regByPhone) {
            this.currentMsisdn = value.phoneLogin;
            this.subSink.sink = this.authService.registerByMsisdnStep1(value.phoneLogin).subscribe(registerObserver);
        } else {
            this.currentEmail = value.emailLogin;
            this.subSink.sink = this.authService.registerByEmailStep1(value.emailLogin).subscribe(registerObserver);
        }
    }

    ngOnDestroy(): void {
        this.subSink.unsubscribe();
    }

    public getMoreOtp(): void {
        if (this.formProcessing) { return; }
        this.regForm.controls['otpCode'].setValue('');
        this.onRegFormStep1Submit(true);
    }

    public onRegFormStep2Submit(): void {
        this.formProcessing = true;
        let errorMessage;
        const value = this.regForm.value;
        const registerObserver = {
            next: () => {
                this.changeStep.emit(3);
                this.onStep3.emit();
                this.formProcessing = false;
            },
            error: (errorData) => {
                this.messageService.clear();
                switch (errorData.error.status) {
                    case 'BAD_REQUEST':
                    case 'WRONG_OTP': {
                        errorMessage = this.regByPhone ? this.nonValidOtpCodeMessage : this.nonValidOtpCodeEmail;
                        break;
                    }
                    case 'OTP_EXPIRED': {
                        errorMessage = this.otpExpiredMessage;
                        break;
                    }
                    case 'OTP_ATTEMPTS_OVER': {
                        errorMessage = this.regByPhone
                            ? this.otpAttemptsOverMessage
                            : this.otpAttemptsOverMessage.replace('SMS', 'e-mail');
                        break;
                    }
                    case 'OTP_LOCKED': {
                        errorMessage = this.otpLockedMessage.replace(
                            'otpLockPeriod',
                            RegistrationComponent.getStringTimerValue(errorData.error.otpLockPeriod),
                        );
                        this.startOtpLockTimer(errorData.error.otpLockPeriod);
                        break;
                    }
                    case 'ANONYMOUS_SESSION_EXPIRED': {
                        errorMessage = this.anonymousSessionExpired;
                        break;
                    }
                    default: {
                        errorMessage =
                            typeof errorData.error === 'string' ? errorData.message : errorData.error.description;
                    }
                }
                if (errorData.status === 504) {
                    errorMessage = this.badRequest;
                }
                this.messageService.add({
                    severity: 'error',
                    summary: '',
                    detail: errorMessage,
                    sticky: true,
                });
                this.formProcessing = false;
            },
        };
        if (this.regByPhone) {
            this.currentMsisdn = value.phoneLogin;
            this.subSink.sink = this.authService
                .registerByMsisdnStep2(value.phoneLogin, value.otpCode, this.anonymousSessionId)
                .subscribe(registerObserver);
        } else {
            this.currentEmail = value.emailLogin;
            this.subSink.sink = this.authService
                .registerByEmailStep2(value.emailLogin, value.otpCode, this.anonymousSessionId)
                .subscribe(registerObserver);
        }
    }

    public backToPreviousStep(): void {
        if (this.sub) {
            this.messageService.clear();
            this.sub.unsubscribe();
        }
        this.changeStep.emit(--this.step);
        if (!this.regByPhone) {
            setTimeout(() => this.regByEmailTab.nativeElement.click(), 0);
        }
    }

    public onRegFormStep3Submit(): void {
        this.formProcessing = true;
        let errorMessage;
        const value = this.regForm.value;
        const registerObserver = {
            next: () => {
                this.router.navigate(['/ride-history']).then(() => {
                    $('#authRegModal').modal('hide');
                    this.formProcessing = false;
                });
            },
            error: (errorData) => {
                this.messageService.clear();
                switch (errorData.error.status) {
                    case 'ANONYMOUS_SESSION_EXPIRED': {
                        errorMessage = this.anonymousSessionExpired;
                        break;
                    }
                    default: {
                        errorMessage =
                            typeof errorData.error === 'string' ? errorData.message : errorData.error.description;
                    }
                }
                if (errorData.status === 504) {
                    errorMessage = this.badRequest;
                }
                this.messageService.add({
                    severity: 'error',
                    summary: '',
                    detail: errorMessage,
                    sticky: true,
                });
                this.formProcessing = false;
            },
        };
        if (this.regByPhone) {
            this.currentMsisdn = value.phoneLogin;
            this.subSink.sink = this.authService
                .registerByMsisdnStep3(value.phoneLogin, value.password, this.anonymousSessionId)
                .subscribe(registerObserver);
        } else {
            this.currentEmail = value.emailLogin;
            this.subSink.sink = this.authService
                .registerByEmailStep3(value.emailLogin, value.password, this.anonymousSessionId)
                .subscribe(registerObserver);
        }
    }

    public showPassword(field): void {
        this[field] = !this[field];
    }

    capchaResponse($event: any) {
        this.capchaChecked = true;
    }

    checkPasswordsMatch() {
        this.regForm.controls['confirmPassword'].updateValueAndValidity();
    }

    private startOtpLockTimer(otpLockPeriod: number) {
        $('#get-more-otp-tab-link').addClass('disabled');
        let currentTimerValue = otpLockPeriod;
        this.sub = interval(1000).subscribe((val) => {
            currentTimerValue = currentTimerValue - 1000;
            if (currentTimerValue < 0) {
                this.messageService.clear();
                $('#get-more-otp-tab-link').removeClass('disabled');
                this.sub.unsubscribe();
                return;
            }
            const otpLockedMessage = this.otpLockedMessage.replace(
                'otpLockPeriod',
                RegistrationComponent.getStringTimerValue(currentTimerValue),
            );
            this.messageService.clear();
            this.messageService.add({
                severity: 'error',
                summary: '',
                detail: otpLockedMessage,
                sticky: true,
            });
            $('.ui-messages-close').click(() => {
                this.messageService.clear();
                this.sub.unsubscribe();
                return;
            });
        });
    }
}
