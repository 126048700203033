import { Injectable, Injector } from '@angular/core';
import { HandleError, HttpErrorHandler } from '../../http/http-error-handler.service';
import { HttpClient } from '@angular/common/http';
import { SessionTokenHolder } from '../domain/session-token-holder';
import { Observable, ReplaySubject } from 'rxjs';
import { SetSessionTokenAction } from '../../app-state/actions/actions';
import { getSessionToken } from '../../app-state/selectors/app.state.selectors';
import { SessionTokenState } from '../../app-state/states/app.states';
import { Store } from '@ngrx/store';
import { catchError, map, tap } from 'rxjs/operators';
import { AuthResponse } from '../domain/auth-response';
import { RegResponse } from '../domain/reg-response';
import { EnvironmentProviderService } from '../../helpers/environment-provider.service';
import { Router } from '@angular/router';
import { ConfigService } from '@ngx-config/core';
import { LocalizationService } from '../../localization/service/localization.service';
declare var $: any;

@Injectable()
export class AuthService {
    private readonly handleError: HandleError;
    private readonly appName: string;
    private checkActivityInterval: any;

    private sessionTokenHolder: SessionTokenHolder;

    public get getSessionTokenHolder(): SessionTokenHolder {
        return this.sessionTokenHolder;
    }

    dataStream: ReplaySubject<any> = new ReplaySubject();

    public dataStream$(): Observable<any> {
        return this.dataStream.asObservable();
    }

    constructor(
        private readonly http: HttpClient,
        private readonly httpErrorHandler: HttpErrorHandler,
        private readonly store: Store<SessionTokenState>,
        private readonly environmentProviderService: EnvironmentProviderService,
        private readonly injector: Injector,
        private readonly configService: ConfigService,
        private readonly localizationService: LocalizationService,
    ) {
        this.handleError = httpErrorHandler.createHandleError('AuthService');
        this.store.select(getSessionToken).subscribe((sessionTokenHolder) => {
            this.sessionTokenHolder = sessionTokenHolder;
        });
        this.appName = this.configService.getSettings('appName');
    }

    public logout(): void {
        this.initListeners(() => {});
        clearInterval(this.checkActivityInterval);
        this.store.dispatch(new SetSessionTokenAction(null));
        localStorage.removeItem('token');
        localStorage.removeItem('passesList');
        localStorage.removeItem('paymentsList');
        localStorage.removeItem('last-action');
        sessionStorage.removeItem('tab');
        this.injector.get(Router).navigate(['main-view']);
        setTimeout(() => {
            $('.modal-backdrop').remove();
        }, 500);
    }

    initAutoLogout() {
        if (!localStorage.getItem('last-action')) {
            localStorage.setItem('last-action', String(Date.now()));
        }
        clearInterval(this.checkActivityInterval);
        const context = this;
        const autoLogoutTime = this.configService.getSettings('autoLogoutTime');

        function check() {
            const lastAction = +localStorage.getItem('last-action');
            const now = Date.now();
            const timeLeft = lastAction + autoLogoutTime * 60 * 1000;
            const diff = timeLeft - now;
            const isTimeout = diff < 0;
            context.initListeners(() => localStorage.setItem('last-action', String(Date.now())));

            if (isTimeout) {
                context.dataStream.next(0);
                context.logout();
            }
        }

        this.checkActivityInterval = setInterval(() => check(), 1000);
    }

    initListeners(reset) {
        document.onmousemove = reset;
        document.onscroll = reset;
        document.onkeydown = reset;
        document.onclick = reset;
        document.onkeypress = reset;
    }

    loginByMsisdn(msisdn: string, password: string): Observable<AuthResponse | any> {
        return this.http
            .post<AuthResponse>('/innersession/create', {
                msisdn: '7' + msisdn,
                password,
                deviceId: this.environmentProviderService.getDeviceId,
                deviceSettings: 'Current browser settings',
                AppName: this.appName,
                AppNameVersion: this.environmentProviderService.getBuildNumber,
                AppOs: this.environmentProviderService.getOS,
                AppOsVersion: this.environmentProviderService.getOSVersion,
                deviceModel: this.environmentProviderService.getDeviceModel,
                MifarePkgSupport: false,
            })
            .pipe(
                map((authResponse) => new SetSessionTokenAction(new SessionTokenHolder(authResponse.sessionId))),
                tap((setSessionTokenAction) => {
                    this.store.dispatch(setSessionTokenAction);
                    localStorage.setItem('token', setSessionTokenAction.payload.sessionToken);
                }),
                catchError(this.handleError('loginByMsisdn', [])),
            );
    }

    loginInnersessionConfirm(msisdn: string, optCode: string): Observable<AuthResponse | any> {
        return this.http
            .post<AuthResponse>('/innersession/confirm', {
                msisdn: '7' + msisdn,
                otp_code: optCode,
            })
            .pipe(
                map((authResponse) => new SetSessionTokenAction(new SessionTokenHolder(authResponse.sessionId))),
                tap((setSessionTokenAction) => {
                    this.store.dispatch(setSessionTokenAction);
                    localStorage.setItem('token', setSessionTokenAction.payload.sessionToken);
                }),
                catchError(this.handleError('loginByMsisdn', [])),
            );
    }
    loginInnersessionOtp(msisdn: string): Observable<AuthResponse | any> {
        return this.http
            .post<AuthResponse>('/innersession/otp', {
                msisdn: '7' + msisdn,
            })
            .pipe(
                catchError(this.handleError('loginByMsisdn', [])),
            );
    }

    loginByEmail(email: string, password: string): Observable<AuthResponse | any> {
        return this.http
            .post<AuthResponse>('/innersession/create', {
                email,
                password,
                deviceId: this.environmentProviderService.getDeviceId,
                deviceSettings: 'Current browser settings',
                AppName: this.appName,
                AppNameVersion: this.environmentProviderService.getBuildNumber,
                AppOs: this.environmentProviderService.getOS,
                AppOsVersion: this.environmentProviderService.getOSVersion,
                DeviceModel: this.environmentProviderService.getDeviceModel,
                MifarePkgSupport: false,
            })
            .pipe(
                map((authResponse) => new SetSessionTokenAction(new SessionTokenHolder(authResponse.sessionId))),
                tap((setSessionTokenAction) => {
                    this.store.dispatch(setSessionTokenAction);
                    localStorage.setItem('token', setSessionTokenAction.payload.sessionToken);
                }),
                catchError(this.handleError('loginByEmail', [])),
            );
    }

    registerByMsisdnStep1(msisdn: string): Observable<RegResponse | any> {
        return this.http
            .post<RegResponse>('/register/start', {
                msisdn: '7' + msisdn,
                deviceId: this.environmentProviderService.getDeviceId,
                deviceSettings: 'Current browser settings',
                AppName: this.appName,
                language: this.localizationService.currentLanguage,
            })
            .pipe(catchError(this.handleError('registerByMsisdnStep1', [])));
    }

    registerByEmailStep1(email: string): Observable<RegResponse | any> {
        return this.http
            .post<RegResponse>('/register/email/start', {
                email,
                deviceId: this.environmentProviderService.getDeviceId,
                deviceSettings: 'Current browser settings',
                AppName: this.appName,
                language: this.localizationService.currentLanguage,
            })
            .pipe(catchError(this.handleError('registerByEmailStep1', [])));
    }

    registerByMsisdnStep2(msisdn: string, otpCode: string, sessionId: string): Observable<RegResponse | any> {
        return this.http
            .post<RegResponse>(
                '/register/msisdn/confirm',
                {
                    userIdentifier: 'msisdn',
                    otp: otpCode,
                    deviceId: this.environmentProviderService.getDeviceId,
                    deviceSettings: 'Current browser settings',
                },
                { headers: { SESSION_ID: sessionId } },
            )
            .pipe(catchError(this.handleError('registerByMsisdnStep2', [])));
    }

    registerByEmailStep2(email: string, otpCode: string, sessionId: string): Observable<RegResponse | any> {
        return this.http
            .post<RegResponse>(
                '/register/email/confirm',
                {
                    otp: otpCode,
                    deviceId: this.environmentProviderService.getDeviceId,
                    deviceSettings: 'Current browser settings',
                },
                { headers: { SESSION_ID: sessionId } },
            )
            .pipe(catchError(this.handleError('registerByEmailStep2', [])));
    }

    registerByMsisdnStep3(msisdn: string, password: string, sessionId: string): Observable<RegResponse | any> {
        return this.http
            .post<RegResponse>(
                '/register/finish',
                {
                    msisdn: '7' + msisdn,
                    password,
                    AppName: this.appName,
                    AppNameVersion: this.environmentProviderService.getBuildNumber,
                    AppOs: this.environmentProviderService.getOS,
                    AppOsVersion: this.environmentProviderService.getOSVersion,
                    DeviceModel: this.environmentProviderService.getDeviceModel,
                    deviceId: this.environmentProviderService.getDeviceId,
                    deviceSettings: 'Current browser settings',
                },
                { headers: { SESSION_ID: sessionId } },
            )
            .pipe(
                map((authResponse) => new SetSessionTokenAction(new SessionTokenHolder(authResponse.sessionId))),
                tap((setSessionTokenAction) => {
                    this.store.dispatch(setSessionTokenAction);
                    localStorage.setItem('token', setSessionTokenAction.payload.sessionToken);
                }),
                catchError(this.handleError('registerByMsisdnStep3', [])),
            );
    }

    registerByEmailStep3(email: string, password: string, sessionId: string): Observable<RegResponse | any> {
        return this.http
            .post<RegResponse>(
                '/register/email/finish',
                {
                    password,
                    AppName: this.appName,
                    AppNameVersion: this.environmentProviderService.getBuildNumber,
                    AppOs: this.environmentProviderService.getOS,
                    AppOsVersion: this.environmentProviderService.getOSVersion,
                    DeviceModel: this.environmentProviderService.getDeviceModel,
                    deviceId: this.environmentProviderService.getDeviceId,
                    deviceSettings: 'Current browser settings',
                },
                { headers: { SESSION_ID: sessionId } },
            )
            .pipe(
                map((authResponse) => new SetSessionTokenAction(new SessionTokenHolder(authResponse.sessionId))),
                tap((setSessionTokenAction) => {
                    this.store.dispatch(setSessionTokenAction);
                    localStorage.setItem('token', setSessionTokenAction.payload.sessionToken);
                }),
                catchError(this.handleError('registerByEmailStep3', [])),
            );
    }

    isAuthenticated() {
        let token: string;
        if (this.sessionTokenHolder) {
            this.initAutoLogout();
            return true;
        } else {
            token = localStorage.getItem('token');
            if (token && token !== 'undefined') {
                this.store.dispatch(new SetSessionTokenAction(new SessionTokenHolder(token)));
                this.initAutoLogout();
                return true;
            }
        }
        return false;
    }

    recoveryByMsisdnStep1(msisdn: string): Observable<RegResponse | any> {
        return this.http
            .post<RegResponse>('/auth/restore/access', {
                msisdn: '7' + msisdn,
                AppName: this.appName,
                language: this.localizationService.currentLanguage,
                AppNameVersion: this.environmentProviderService.getBuildNumber,
                deviceId: this.environmentProviderService.getDeviceId,
                deviceSettings: 'Current browser settings',
            })
            .pipe(catchError(this.handleError('recoveryByMsisdnStep1', [])));
    }

    recoveryByEmailStep1(email: string): Observable<RegResponse | any> {
        return this.http
            .post<RegResponse>('/auth/restore/access', {
                email,
                AppName: this.appName,
                language: this.localizationService.currentLanguage,
                AppNameVersion: this.environmentProviderService.getBuildNumber,
                deviceId: this.environmentProviderService.getDeviceId,
                deviceSettings: 'Current browser settings',
            })
            .pipe(catchError(this.handleError('recoveryByEmailStep1', [])));
    }

    recoveryByMsisdnStep2(msisdn: string, otpCode: string, sessionId: string) {
        return this.http
            .post<RegResponse>(
                '/restore/confirm',
                {
                    userIdentifier: 'msisdn',
                    otp: otpCode,
                    AppName: this.appName,
                    language: this.localizationService.currentLanguage,
                    AppNameVersion: this.environmentProviderService.getBuildNumber,
                    deviceId: this.environmentProviderService.getDeviceId,
                    deviceSettings: 'Current browser settings',
                },
                { headers: { SESSION_ID: sessionId } },
            )
            .pipe(catchError(this.handleError('registerByMsisdnStep2', [])));
    }

    recoveryByEmailStep2(email: string, otpCode: string, sessionId: string) {
        return this.http
            .post<RegResponse>(
                '/restore/confirm',
                {
                    userIdentifier: 'email',
                    otp: otpCode,
                    AppName: this.appName,
                    language: this.localizationService.currentLanguage,
                    AppNameVersion: this.environmentProviderService.getBuildNumber,
                    deviceId: this.environmentProviderService.getDeviceId,
                    deviceSettings: 'Current browser settings',
                },
                { headers: { SESSION_ID: sessionId } },
            )
            .pipe(catchError(this.handleError('recoveryByEmailStep2', [])));
    }

    recoveryByMsisdnStep3(msisdn: string, password: string, sessionId: string) {
        return this.http
            .post<RegResponse>(
                '/auth/credentials',
                {
                    userIdentifier: 'msisdn',
                    password,
                    AppName: this.appName,
                    language: this.localizationService.currentLanguage,
                    AppNameVersion: this.environmentProviderService.getBuildNumber,
                    deviceId: this.environmentProviderService.getDeviceId,
                    deviceSettings: 'Current browser settings',
                },
                { headers: { SESSION_ID: sessionId } },
            )
            .pipe(
                map((authResponse) => new SetSessionTokenAction(new SessionTokenHolder(authResponse.sessionId))),
                tap((setSessionTokenAction) => {
                    this.store.dispatch(setSessionTokenAction);
                    localStorage.setItem('token', setSessionTokenAction.payload.sessionToken);
                }),
                catchError(this.handleError('recoveryByMsisdnStep3', [])),
            );
    }

    recoveryByEmailStep3(email: string, password: string, sessionId: string) {
        return this.http
            .post<RegResponse>(
                '/auth/credentials',
                {
                    userIdentifier: 'email',
                    password,
                    AppName: this.appName,
                    language: this.localizationService.currentLanguage,
                    AppNameVersion: this.environmentProviderService.getBuildNumber,
                    deviceId: this.environmentProviderService.getDeviceId,
                    deviceSettings: 'Current browser settings',
                },
                { headers: { SESSION_ID: sessionId } },
            )
            .pipe(
                map((authResponse) => new SetSessionTokenAction(new SessionTokenHolder(authResponse.sessionId))),
                tap((setSessionTokenAction) => {
                    this.store.dispatch(setSessionTokenAction);
                    localStorage.setItem('token', setSessionTokenAction.payload.sessionToken);
                }),
                catchError(this.handleError('recoveryByEmailStep3', [])),
            );
    }

    deleteUserProfile(): Observable<any> {
        let options = {};
        if (!localStorage.getItem('token')) {
            options = { headers: { SESSION_ID: sessionStorage.getItem('sessionId') } };
        }
        return this.http.delete('/profile/delete', options)
            .pipe(catchError(this.handleError('deleteUserProfile', [])));
    }

    acceptPrivatePolicy(): Observable<any> {
        return this.http.post(
            '/profile/consent',
            {},
            { headers: { SESSION_ID: sessionStorage.getItem('sessionId') } }
        )
            .pipe(
                map(() => new SetSessionTokenAction(new SessionTokenHolder(sessionStorage.getItem('sessionId')))),
                tap((setSessionTokenAction) => {
                    this.store.dispatch(setSessionTokenAction);
                    localStorage.setItem('token', setSessionTokenAction.payload.sessionToken);
                }),
                catchError(this.handleError('acceptPrivatePolicy', []))
            );
    }
}
