<ng-container [ngSwitch]="step">
    <div *ngSwitchCase="1">
        <nav>
            <div class="nav nav-tabs d-flex justify-content-between" id="nav-tab" role="tablist">
                <a
                    id="phone-tab-link"
                    class="nav-item nav-link active sml"
                    data-toggle="tab"
                    href="#phoneInputReg"
                    (click)="regByPhone = true"
                    >{{ 'login.loginByPhoneNumberLinkTitle' | translate }}</a
                >

                <a *ngIf="isEnabledEmail"
                    id="email-tab-link"
                    class="nav-item nav-link sml"
                    data-toggle="tab"
                    href="#emailInputReg"
                    #regByEmailTab
                    (click)="regByPhone = false"
                    >{{ 'login.loginByEmailNumberLinkTitle' | translate }}</a
                >
            </div>
        </nav>
        <form [formGroup]="regForm" (ngSubmit)="onRegFormStep1Submit()">
            <div class="form-group tab-content pt-1">
                <div id="phoneInputReg" class="tab-pane fade show active">
                    <input
                        id="regPhone"
                        type="tel"
                        class="form-control"
                        formControlName="phoneLogin"
                        mask="000 000 00 00"
                        prefix="+7 "
                        [showMaskTyped]="true"
                        placeHolderCharacter=" "
                    />
                    <span
                        class="validation-error"
                        *ngIf="!regForm.controls['phoneLogin'].valid && regForm.controls['phoneLogin'].dirty"
                    >
                        {{ 'fieldIsRequred' | translate }}
                    </span>
                </div>
                <div id="agreementCheckboxReg" class="tab-pane fade show active">
                    <label class="checkbox-wrapper">
                        <input
                            id="agreementCheckboxRegField"
                            type="checkbox"
                            class="checkbox-wrapper-checkbox"
                            formControlName="agreementReg"
                        />
                        <span>
                            Соглашаюсь с правилами использования <a href="https://metro.spb.ru/" target="_blank">сервиса</a>
                        </span>
                    </label>
                    <span
                        class="validation-error"
                        *ngIf="!regForm.controls['agreementReg'].valid && regForm.controls['agreementReg'].dirty"
                    >
                        {{ 'fieldIsRequred' | translate }}
                    </span>
                </div>
                <div id="emailInputReg" class="tab-pane fade">
                    <input
                        id="regEmail"
                        class="form-control"
                        formControlName="emailLogin"
                        [textMask]="{ mask: emailMask }"
                        placeholder="example@domain.com"
                    />
                    <span
                        class="validation-error"
                        *ngIf="!regForm.controls['emailLogin'].valid && regForm.controls['emailLogin'].dirty"
                    >
                        {{ 'fieldIsRequred' | translate }}
                    </span>
                </div>
                <div class="d-flex justify-content-center pt-4"
                     *ngIf="isEnabledRecaptcha">
                    <re-captcha
                            (resolved)="capchaResponse($event)"
                            siteKey="{{ reCaptchaSitekey }}"
                    ></re-captcha>
                </div>
                <div class="w-100 pt-4">
                    <p-messages></p-messages>
                </div>
                <div class="pt-4 d-flex justify-content-between">
                    <button type="button" class="btn button-white" data-toggle="modal" data-dismiss="modal">
                        {{ 'cancelButtonLabel' | translate }}
                    </button>
                    <button type="submit"
                            class="btn button-red"
                            data-toggle="modal"
                            [disabled]="!isValidByStep1() || formProcessing">
                        {{ 'nextButtonLabel' | translate }}
                    </button>
                </div>
            </div>
        </form>
    </div>
    <div *ngSwitchCase="2">
        <div class="d-flex justify-content-center align-items-center">
            <div class="flex-column pt-md-3 px-4 centered-content">
                <div *ngIf="regByPhone" class="px-4">
                    <span>{{ step2Content1 }}</span>
                    <span>{{ this.currentMsisdn | mask: '000 000-00-00' }}</span>
                    <span>{{ step2Content2 }}</span>
                </div>
                <div *ngIf="!regByPhone">
                    <div>{{ step2EmailContent1 }}</div>
                    <div>{{ step2EmailContent2 }}</div>
                </div>
            </div>
        </div>
        <nav>
            <div class="nav nav-tabs d-flex justify-content-between pt-3" id="nav-tab2" role="tablist">
                <a
                    id="otp-tab-link"
                    class="nav-item nav-link active sml"
                    data-toggle="tab"
                    href="#otpInputReg"
                    #codeFrom
                    >{{ regByPhone ? otpLinkTitle.replace('{0}', 'SMS') : otpLinkTitle.replace('{0}', 'e-mail') }}</a
                >

                <a
                    id="get-more-otp-tab-link"
                    class="nav-item nav-link sml"
                    data-toggle="tab"
                    href="#otpInputReg"
                    (click)="getMoreOtp()"
                    >{{ 'reg.getMoreOtpLinkTitle' | translate }}</a
                >
            </div>
        </nav>
        <form [formGroup]="regForm" (ngSubmit)="onRegFormStep2Submit()">
            <div class="form-group tab-content pt-1">
                <div id="otpInputReg" class="tab-pane fade show active">
                    <input id="otpCode" class="form-control" formControlName="otpCode" mask="000000" (keyup)="onKeyUp()"/>
                    <span
                        class="validation-error"
                        *ngIf="!regForm.controls['otpCode'].valid && regForm.controls['otpCode'].dirty"
                    >
                        {{ 'fieldIsRequred' | translate }}
                    </span>
                </div>
                <div class="w-100 pt-4">
                    <div class="message-wrapper">
                        <p-messages></p-messages>
                    </div>
                </div>
                <div class="pt-4 d-flex justify-content-between">
                    <button type="button" class="btn button-white" data-toggle="modal" (click)="backToPreviousStep()">
                        {{ 'backButtonLabel' | translate }}
                    </button>
                    <button type="submit"
                            class="btn button-red"
                            data-toggle="modal"
                            [disabled]="!isValidByStep2() || formProcessing">
                        {{ 'nextButtonLabel' | translate }}
                    </button>
                </div>
            </div>
        </form>
    </div>
    <div *ngSwitchCase="3">
        <div class="tab-pane show active w-100 pb-3">
            <form [formGroup]="regForm" (ngSubmit)="onRegFormStep3Submit()">
                <div class="form-group pt-1">
                    <label for="password">{{ 'reg.passwordLabel' | translate }}</label>
                    <div class="input-group input-group-seamless-append" id="password">
                        <input
                            [attr.type]="passwordShown ? 'text' : 'password'"
                            class="form-control pwd"
                            autocomplete="new-password"
                            formControlName="password"
                            tabindex="1"
                            (input)="checkPasswordsMatch()"
                        />
                        <div class="input-group-append">
                            <button
                                class="show-password-img-button"
                                type="button"
                                (click)="showPassword('passwordShown')"
                            >
                                <img src="assets/layout/images/show-password-eye-inactive.svg" *ngIf="!passwordShown" />
                                <img src="assets/layout/images/show-password-eye-active.svg" *ngIf="passwordShown" />
                            </button>
                        </div>
                    </div>
                    <span
                        class="validation-error"
                        *ngIf="!regForm.controls['password'].valid && regForm.controls['password'].dirty"
                    >
                        <span *ngIf="regForm.controls['password'].errors['notValid']">{{
                            'reg.passwordNotValidMessage' | translate
                        }}</span>
                        <span *ngIf="regForm.controls['password'].errors['required']">{{
                            'fieldIsRequred' | translate
                        }}</span>
                    </span>
                </div>

                <div class="form-group pt-3">
                    <label for="confirmPassword">{{ 'reg.confirmPasswordLabel' | translate }}</label>
                    <div class="input-group input-group-seamless-append" id="confirmPassword">
                        <input
                            [attr.type]="confirmPasswordShown ? 'text' : 'password'"
                            class="form-control pwd"
                            autocomplete="new-password"
                            formControlName="confirmPassword"
                            tabindex="2"
                        />
                        <div class="input-group-append">
                            <button
                                class="show-password-img-button"
                                type="button"
                                (click)="showPassword('confirmPasswordShown')"
                            >
                                <img
                                    src="assets/layout/images/show-password-eye-inactive.svg"
                                    *ngIf="!confirmPasswordShown"
                                />
                                <img
                                    src="assets/layout/images/show-password-eye-active.svg"
                                    *ngIf="confirmPasswordShown"
                                />
                            </button>
                        </div>
                    </div>
                    <span
                        class="validation-error"
                        *ngIf="!regForm.controls['confirmPassword'].valid && regForm.controls['confirmPassword'].dirty"
                    >
                        <span *ngIf="regForm.controls['confirmPassword'].errors['required']">{{
                            'fieldIsRequred' | translate
                        }}</span>
                        <span *ngIf="regForm.controls['confirmPassword'].errors['mismatchedPassword']">{{
                            'reg.passwordNotSameNewMessage' | translate
                        }}</span>
                    </span>
                </div>

                <div *ngIf="isEnabledUserAgreement"
                     class="form-group pt-3 form-check">
                    <input
                        type="checkbox"
                        class="form-check-input"
                        id="userAgreement"
                        formControlName="userAgreement"
                        tabindex="3"
                    />
                    <label class="form-check-label" for="userAgreement">
                        {{ 'reg.agreePart1' | translate }}
                        <a class="mug-link font-weight-bold" [href]="personalDataLink">
                            {{ 'reg.agreePart2' | translate }}
                        </a>
                    </label>
                </div>

                <div class="w-100 py-3">
                    <p-messages></p-messages>
                </div>
                <div class="pt-3 d-flex justify-content-between">
                    <button type="button" class="btn button-white" data-toggle="modal" data-dismiss="modal">
                        {{ 'cancelButtonLabel' | translate }}
                    </button>
                    <button
                        type="submit"
                        class="btn button-red"
                        data-toggle="modal"
                        [disabled]="!isValidByStep3() || formProcessing"
                        tabindex="4"
                    >
                        {{ 'readyButtonLabel' | translate }}
                    </button>
                </div>
            </form>
        </div>
    </div>
</ng-container>
